$(document).ready(
  function () {
    $.when(
      config
    ).done(function (config) {
      $('#reset_password').validate({
        rules: {
          password1: {
            required: true,
            minlength: config.min_chars_reset_password
          },
          password2: {
            equalTo: 'input[name=password1]',
            required: true,
            minlength: config.min_chars_reset_password
          }
        },
        errorPlacement: function (error, element) {
          if (element.attr('name') === 'password1') {
            error.appendTo($('#password1ErrorDiv'))
          }
          if (element.attr('name') === 'password2') {
            error.appendTo($('#password2ErrorDiv'))
          }
        },
        submitHandler: function (form) {
          form.submit()
        }
      })

      $('#complete_dealer_registration').validate({
        rules: {
          password1: {
            required: true,
            minlength: config.min_chars_reset_password
          },
          password2: {
            equalTo: 'input[name=password1]',
            required: true,
            minlength: config.min_chars_reset_password
          }
        },
        errorPlacement: function (error, element) {
          if (element.attr('name') === 'password1') {
            error.appendTo($('#password1ErrorDiv'))
          }
          if (element.attr('name') === 'password2') {
            error.appendTo($('#password2ErrorDiv'))
          }
        },
        submitHandler: function (form) {
          form.submit()
        }
      })
    })

    $('#enter_email').validate({
      rules: {
        email: {
          required: true,
          email: true
        }
      },
      errorPlacement: function (error, element) {
        if (element.attr('name') === 'email') {
          error.appendTo($('#emailErrorDiv'))
        }
      },
      submitHandler: function (form) {
        form.submit()
      }
    })

    $('#loginForm').validate({
      rules: {
        email: {
          required: true
        },
        password: {
          required: true
        }
      },
      errorPlacement: function (error, element) {
        if (element.attr('name') === 'email') {
          error.appendTo($('#emailErrorDiv'))
        }
        if (element.attr('name') === 'password') {
          error.appendTo($('#passwordErrorDiv'))
        }
      },
      submitHandler: function (form) {
        form.submit()
      }
    })
  })

var config = $.getJSON('/static/config/config.json')
